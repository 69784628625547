<template>
  <div class="head fixed">
    <div class="content">
      <div class="logo">
        <img :src="logo" alt="LOGO" v-if="logo" />
        <span v-else>LOGO</span>
      </div>
      <ul class="nav">
        <template v-for="(item, index) in menus" :key="index">
          <li
            v-if="index < (menus.length > 8 ? 7 : 8)"
            :class="{
              active:
                item.activePath === '/'
                  ? nowPath === '/'
                  : item.activePath === '/news'
                  ? nowPath.indexOf('/news') !== -1 &&
                    nowPath.indexOf('/newstaff') === -1
                  : nowPath.indexOf(item.activePath) !== -1,
            }"
          >
            <router-link :to="item.path">{{ item.name }}</router-link>
          </li>
        </template>
        <template v-if="menus.length > (menus.length > 8 ? 7 : 8)">
          <li>
            <div class="more-menus">
              <a class="more" href="javascript:;">
                <EllipsisOutlined
                  :style="{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    paddingTop: '4px',
                  }"
                />
              </a>
              <div class="more-menus-wrap">
                <div class="more-menus-box">
                  <template v-for="(item, index) in menus" :key="index">
                    <div
                      class="more-menus-item"
                      :class="{
                        active:
                          item.activePath === '/'
                            ? nowPath === '/'
                            : item.activePath === '/news'
                            ? nowPath.indexOf('/news') !== -1 &&
                              nowPath.indexOf('/newstaff') === -1
                            : nowPath.indexOf(item.activePath) !== -1,
                      }"
                      v-if="index + 1 > (menus.length > 8 ? 7 : 8)"
                    >
                      <router-link :to="item.path">{{ item.name }}</router-link>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </li>
        </template>
      </ul>
      <div class="right">
        <div class="bell" :class="{ msg: msgs }" @click="toMsg">
          <BellOutlined />
        </div>
        <a
          class="link"
          v-if="adminLink"
          :href="`${adminLink}${newWindow == 1 ? '?ddtab=true' : ''}`"
          :target="newWindow == 1 ? '_blank' : '_self'"
          >后台管理</a
        >
        <div class="avatar">
          <div class="img" @click="toMine('profile')">
            <img :src="user.portrait" alt="头像" />
          </div>
          <div class="user-box">
            <ul>
              <li @click="toMine('profile')"><UserOutlined />个人信息</li>
              <!-- <li class="sm-menu" v-if="units && units.length > 0">
                <BranchesOutlined />切换账号
                <div class="sub-menu">
                  <div
                    class="menu-item"
                    v-for="(item, index) in units"
                    :key="index"
                    @click="switchUnit(item)"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </li> -->
              <li @click="toTeacher" v-if="isTeacher == 1 || isTeacher == 2">
                <SolutionOutlined />讲师专区
              </li>
              <li @click="toLogout" v-if="!ISHTSY">
                <PoweroffOutlined />退出登录
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, createVNode } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import ls from "@/utils/local-storage";
import {
  userInfo,
  msgCount,
  logout,
  companyInfo,
  wxworkConfig,
  switchunit,
  isLecturer,
  enterpriseList,
} from "@/api/user";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import { ToLogin } from "@/utils/request.js";
import { platformConfig } from "@/api/other";
import { currentHost } from "@/utils/request.js";

export default {
  name: "Head",
  setup() {
    const ISHTSY = process.env.VUE_APP_CLIENT === "htsy";
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    // 导航
    let nowPath = computed(() => route.path);
    const menus = ref([
      {
        name: "首页",
        path: "/",
        activePath: "/",
      },
      {
        name: "学习中心",
        path: {
          name: "mine",
        },
        activePath: "/mine",
      },
    ]);

    // 获取用户信息
    let user = computed(() => store.getters.userInfo);
    let units = ref([]);
    userInfo().then((res) => {
      if (res.ret === 0) {
        units.value = res.data.units;
        store.dispatch("setUserInfo", res.data);
      }
    });

    // 是否是讲师
    const isTeacher = ref(4);
    isLecturer().then((res) => {
      if (res.ret === 0) {
        // 1 是讲师&申请 2 是讲师&后台添加 3 非讲师&有记录 4 非讲师&没记录
        isTeacher.value = res.data;
      }
    });

    // 获取企业信息
    let logo = ref("");
    let menu = ref([]);
    companyInfo().then((res) => {
      if (res.ret == 0) {
        logo.value = res.data.logo1;
        menu.value = res.data.menu;
        store.dispatch("setCompanyInfo", res.data);
        let hasProject = false;
        res.data.menu.forEach((item) => {
          switch (item) {
            case "course":
              menus.value.push({
                name: "课程中心",
                path: "/course",
                activePath: "/course",
              });
              break;
            case "project":
            case "plan":
              if (!hasProject) {
                hasProject = true;
                menus.value.push({
                  name: "培训项目",
                  path: "/project",
                  activePath: "/project",
                });
              }
              break;
            case "package":
              menus.value.push({
                name: "继续教育",
                path: "/package",
                activePath: "/package",
              });
              break;
            case "newstaff":
              menus.value.push({
                name: "新人培训",
                path: "/newstaff",
                activePath: "/newstaff",
              });
              break;
            case "subject":
              menus.value.push({
                name: "课程专题",
                path: "/subject",
                activePath: "/subject",
              });
              break;
            case "map":
              menus.value.push({
                name: "学习地图",
                path: "/map",
                activePath: "/map",
              });
              break;
            case "knowledge":
              menus.value.push({
                name: "知识库",
                path: "/knowledge",
                activePath: "/knowledge",
              });
              break;
            case "news":
              menus.value.push({
                name: "新闻中心",
                path: "/news",
                activePath: "/news",
              });
              break;
            case "lecturer":
              menus.value.push({
                name: "讲师中心",
                path: "/lecturer",
                activePath: "/lecturer",
              });
              break;
            case "enterprise":
              enterpriseList().then((res) => {
                if (res.data) {
                  menus.value.push({
                    name: "单位管理",
                    path: "/enterprise",
                    activePath: "/enterprise",
                  });
                }
              });
              break;
            case "signup":
              menus.value.push({
                name: "报名中心",
                path: "/signup",
                activePath: "/signup",
              });
              break;
          }
        });
      }
    });

    // 获取消息数
    let msgs = computed(() => store.getters.msgCount);
    msgCount().then((res) => {
      if (res.ret === 0) {
        store.dispatch("setMsgCount", res.data);
      }
    });

    let adminLink = ref("");
    adminLink.value = ls.get("adminLink");

    let showLogout = ref(false);
    showLogout.value = ls.get("platform") === "web";

    const toMine = (currentView) => {
      router.push("/mine#" + currentView);
    };

    const toMsg = () => {
      router.push({
        path: "/mine/msg",
      });
    };

    const toTeacher = () => {
      router.push({
        path: "/teacher",
      });
    };

    const toLogout = () => {
      Modal.confirm({
        title: () => "确定要退出登录吗？",
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          logout().then((res) => {
            ToLogin();
          });
        },
      });
    };

    const switchUnit = (item) => {
      switchunit(item.id).then((res) => {
        window.location.href =
          location.protocol +
          "//" +
          item.studyDomain +
          "/login/switch?key=" +
          res.data.key;
      });
    };

    platformConfig({ site: currentHost }).then((res) => {
      if (res.data.theme) {
        document.body.style.setProperty("--theme", res.data.theme);
      }
      if (res.data.platform == 26) {
        wxworkConfig({
          url:
            location.protocol +
            "//" +
            location.hostname +
            location.pathname +
            location.port +
            location.search,
        }).then((res) => {
          let d = res.data;
          wx.agentConfig({
            corpid: d.corpId,
            agentid: d.agentid,
            timestamp: d.timestamp,
            nonceStr: d.nonceStr,
            signature: d.signature,
            jsApiList: ["selectExternalContact"],
            success: function (res) {
              // 回调
            },
            fail: function (res) {
              if (res.errMsg.indexOf("function not exist") > -1) {
                alert("版本过低请升级");
              }
            },
          });
        });
      }
    });

    return {
      ISHTSY,
      newWindow: store.getters.companyInfo.useNewWindow,
      logo,
      nowPath,
      menus,
      user,
      units,
      menu,
      adminLink,
      isTeacher,
      showLogout,
      msgs,
      toMine,
      toMsg,
      toLogout,
      toTeacher,
      switchUnit,
    };
  },
};
</script>

<style>
* {
  font-family: "Microsoft YaHei" !important;
}
.ant-modal-confirm-btns .ant-btn-primary {
  background: var(--theme) !important;
  border-color: var(--theme) !important;
}
.ant-modal-confirm-btns .ant-btn:hover,
.ant-modal-confirm-btns .ant-btn:focus {
  color: var(--theme) !important;
  border-color: var(--theme) !important;
}
.ant-modal-confirm-btns .ant-btn-primary:hover,
.ant-modal-confirm-btns .ant-btn-primary:focus {
  color: #fff !important;
}
</style>
<style lang="less" scoped>
.head {
  height: 64px;
  background-color: @color-theme;
  z-index: 999;
  .content {
    height: 100%;
    .mixinWrap();
    .mixinFlex(space-between; center);
    .logo {
      .mixinImgWrap(132px; 32px);
      span {
        font-size: 18px;
        letter-spacing: 4px;
        .mixinFlex(center; center);
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
      }
    }
    .nav {
      .mixinFlex(flex-start; center);
      width: calc(100% - 406px);
      padding-left: 20px;
      box-sizing: border-box;
      li {
        font-size: 16px;
        font-weight: bold;
        &:not(:last-child) {
          margin-right: 18px;
        }
        a {
          padding: 17px 10px;
          display: inline-block;
          color: #fff;
          opacity: 0.75;
          position: relative;
          transition: all 0.3s;
          font-family: "Microsoft YaHei";
          &::after {
            content: "";
            position: absolute;
            bottom: 3px;
            left: 50%;
            width: 0;
            height: 2px;
            background-color: #ef7d6d;
            transition: all 0.3s;
          }
        }
        &:hover,
        &.active {
          a {
            opacity: 1;
            &::after {
              width: 26px;
              left: calc(50% - 13px);
            }
          }
        }
        .more-menus {
          position: relative;
          .more {
            padding: 17px 10px;
          }
          .more-menus-wrap {
            position: absolute;
            top: 70px;
            left: 0;
            padding-top: 4px;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s;
            z-index: 10000;
            .more-menus-box {
              width: max-content;
              background-color: @color-theme;
              border-radius: 4px;
              padding: 10px;
              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);
            }
            .more-menus-item {
              a {
                font-family: "Microsoft YaHei";
                padding: 7px 10px;
                color: #fff;
                opacity: 0.75;
                &::after {
                  content: none;
                }
              }
              &:hover,
              &.active {
                a {
                  opacity: 1;
                  &::after {
                    content: none;
                  }
                }
              }
            }
          }
          &:hover {
            .more-menus-wrap {
              top: 64px;
              opacity: 1;
              visibility: initial;
            }
          }
        }
      }
    }
    .right {
      .mixinFlex(flex-end; center);
      width: 162px;
      .bell {
        color: #fff;
        font-size: 22px;
        position: relative;
        cursor: pointer;
        &.msg::before {
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #ff0000;
          position: absolute;
          top: 2px;
          right: 0;
          z-index: 10;
        }
      }
      .link {
        color: #fff;
        margin-left: 20px;
      }
      .avatar {
        width: 36px;
        height: 64px;
        padding: 14px 0;
        margin-left: 20px;
        position: relative;
        .img {
          .mixinImgWrap(36px; 36px);
          border-radius: 50%;
          cursor: pointer;
        }
        .user-box {
          z-index: 10000;
          position: absolute;
          right: 0;
          top: 70px;
          padding-top: 4px;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s;
          ul {
            width: 112px;
            background-color: @color-theme;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);
            border-radius: 4px;
            padding: 5px 0;
            li {
              height: 35px;
              font-size: 14px;
              .mixinFlex(center; center);
              cursor: pointer;
              color: #fff;
              opacity: 0.75;
              transition: all 0.3s;
              span {
                margin-right: 8px;
              }
              &:hover {
                opacity: 1;
              }
              &.sm-menu {
                position: relative;
                .sub-menu {
                  color: #202020;
                  background-color: #fff;
                  position: absolute;
                  right: 126px;
                  top: -5px;
                  width: max-content;
                  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
                  border-radius: 4px;
                  padding: 5px 0;
                  opacity: 0;
                  visibility: hidden;
                  transition: all 0.3s;
                  .menu-item {
                    height: 35px;
                    line-height: 35px;
                    padding: 0 16px;
                    &:hover {
                      color: @color-theme;
                      background-color: #eeeeee;
                    }
                  }
                }
                &:hover {
                  .sub-menu {
                    right: 114px;
                    opacity: 1;
                    visibility: initial;
                  }
                }
              }
            }
          }
        }
        &:hover {
          .user-box {
            top: 64px;
            opacity: 1;
            visibility: initial;
          }
        }
      }
    }
  }
  // &.fixed {
  //   position: fixed;
  //   left: 0;
  //   right: 0;
  //   background-color: transparent;
  //   border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  //   .logo {
  //     filter: grayscale(100%) brightness(560%);
  //     span {
  //       color: #fff;
  //     }
  //   }
  //   .nav {
  //     li {
  //       a {
  //         color: #fff;
  //       }
  //       &.active {
  //         a {
  //           color: #fff;
  //         }
  //       }
  //     }
  //   }
  // }
}
</style>
